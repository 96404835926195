




import {Component, Vue} from 'vue-property-decorator';
import MetaMixin from '@/mixins/meta.mixin';
import RouteMixin from '@/mixins/route.mixin';
import {HfPage} from '@/model/site/components.model';
import StaticPage from '@/pages/static/StaticPage.vue';

@Component({

  mixins: [MetaMixin, RouteMixin],
  metaInfo() {
    return this.metaInfo;
  },
  components: {StaticPage},
})
export default class PrivacyNoticePage extends Vue implements HfPage {

  get headTitle() {
    return 'Datenschutzerklärung';
  }

  get headDescription() {
    return 'Die hockeyfans.ch GmbH sammelt keine persönlichen Nutzerdaten. Durch sogenannte Cookies werden lediglich ' +
      'folgende Informationen auf dem lokalen Computer gespeichert zur Personalisierung der Seite für den Benutzer: ' +
      '1. Lieblingsclub, 2. Foren-Login. ' +
      'Unpersönliche Nutzerdaten (z.B. Zeit und Herkunftsland des Zugriffs, technische Daten wie der verwendete' +
      ' Browser) können zu Marktforschungs- und Website-Optimierungszwecken von der hockeyfans.ch GmbH oder ' +
      'Partnerfirmen im Sinne von statistischen Auswertungen gesammelt werden, ebenso Logfile-Daten durch die ' +
      'hockeyfans.ch GmbH bzw. deren Hosting-Partner.';
  }

  get canonicalUrl(): string {
    return this.urlFromCurrentPath;
  }

}
