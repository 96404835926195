

















import {Component, Vue} from 'vue-property-decorator';
import PanelBox from '@/components/ui/PanelBox.vue';
import LoremIpsum from '@/components/test/LoremIpsum.vue';
import SportLayout from '@/components/layout/SportLayout.vue';
import HfAdBox from '@/components/ads/HfAdBox.vue';

@Component({
  components: {HfAdBox, SportLayout, LoremIpsum, PanelBox},
})
export default class StaticPage extends Vue {

}
