




import {Component, Vue} from 'vue-property-decorator';
import MetaMixin from '@/mixins/meta.mixin';
import RouteMixin from '@/mixins/route.mixin';
import {HfPage} from '@/model/site/components.model';
import StaticPage from '@/pages/static/StaticPage.vue';

@Component({

  mixins: [MetaMixin, RouteMixin],
  metaInfo() {
    return this.metaInfo;
  },
  components: {StaticPage},
})
export default class FundingPage extends Vue implements HfPage {

  get headTitle() {
    return 'Impressum';
  }

  get headDescription() {
    return 'Hockeyfans.ch GmbH, Kapellstr. 12, 8853 Lachen' +
      'Tel. 044 586 48 19, E-Mail ges@hockeyfans.ch, Firmen-/MWST-Nummer: CHE-112.615.686' +
      'Bankverbindung: Nr. PC-Konto: 60-512390-5, Empfänger: hockeyfans.ch GmbH, 8853 Lachen';
  }

  get canonicalUrl(): string {
    return this.urlFromCurrentPath;
  }

}
