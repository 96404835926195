





































import {Component, Inject, Prop, Vue} from 'vue-property-decorator';
import {nil} from '@/utils/common.util';
import {ContentContainer} from '@/model/site/content.model';
import {ContentService} from '@/services/content.service';
import ContainerLayout from '@/components/layout/ContainerLayout.vue';
import PanelBox from '@/components/ui/PanelBox.vue';

@Component({
  components: {PanelBox, ContainerLayout},
})
export default class StaticPage extends Vue {

  @Prop({required: true}) staticId!: string;
  @Inject('contentService') contentService!: ContentService;

  container?: ContentContainer = nil();

  created() {
    this.contentService.staticContent(this.staticId)
      .then((container: ContentContainer) => {
        this.container = container;
      })
      .catch((error) => {
        this.$log.error(`Could not load static content with id ${this.staticId}`, error);
      });
  }
}
